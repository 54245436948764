import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import type { IconName as FaIcon } from '@fortawesome/fontawesome-svg-core';
import dynamic from 'next/dynamic';
import type { MouseEvent } from 'react';
import { Icon as CustomIcon, ICON_NAMES, ICON_STYLE_DEFAULT } from '../../constants/icon';
import { IconStyle } from '../../constants/styling';
import '../../utils/fontAwesomeUtil';
import { StyledIcon, StyledIconProps } from './Icon.styled';

const Ban = dynamic(() => import('../../icons/krefel').then((mod) => mod.Ban));
const Bars = dynamic(() => import('../../icons/krefel').then((mod) => mod.Bars));
const Cart = dynamic(() => import('../../icons/krefel').then((mod) => mod.Cart));
const CartPlus = dynamic(() => import('../../icons/krefel').then((mod) => mod.CartPlus));
const Check = dynamic(() => import('../../icons/krefel').then((mod) => mod.Check));
const CheckCircle = dynamic(() => import('../../icons/krefel').then((mod) => mod.CheckCircle));
const CheckCircleSolid = dynamic(() => import('../../icons/krefel').then((mod) => mod.CheckCircleSolid));
const Chevron = dynamic(() => import('../../icons/krefel').then((mod) => mod.Chevron));
const Circle = dynamic(() => import('../../icons/krefel').then((mod) => mod.Circle));
const ClickCollect = dynamic(() => import('../../icons/krefel').then((mod) => mod.ClickCollect));
const Clock = dynamic(() => import('../../icons/krefel').then((mod) => mod.Clock));
const Download = dynamic(() => import('../../icons/krefel').then((mod) => mod.Download));
const Edit = dynamic(() => import('../../icons/krefel').then((mod) => mod.Edit));
const Envelope = dynamic(() => import('../../icons/krefel').then((mod) => mod.Envelope));
const ExclamationCircle = dynamic(() => import('../../icons/krefel').then((mod) => mod.ExclamationCircle));
const Eye = dynamic(() => import('../../icons/krefel').then((mod) => mod.Eye));
const EyeSlash = dynamic(() => import('../../icons/krefel').then((mod) => mod.EyeSlash));
const Filter = dynamic(() => import('../../icons/krefel').then((mod) => mod.Filter));
const Heart = dynamic(() => import('../../icons/krefel').then((mod) => mod.Heart));
const HeartFull = dynamic(() => import('../../icons/krefel').then((mod) => mod.HeartFull));
const InfoCircle = dynamic(() => import('../../icons/krefel').then((mod) => mod.InfoCircle));
const List = dynamic(() => import('../../icons/krefel').then((mod) => mod.List));
const Location = dynamic(() => import('../../icons/krefel').then((mod) => mod.Location));
const LocationPinWithBoxIcon = dynamic(() => import('./LocationPinWithBoxIcon/LocationPinWithBoxIcon'));
const Logo = dynamic(() => import('../../icons/krefel').then((mod) => mod.Logo));
const MapMarkerAlt = dynamic(() => import('../../icons/krefel').then((mod) => mod.MapMarkerAlt));
const MenuProduct = dynamic(() => import('../../icons/krefel').then((mod) => mod.MenuProduct));
const MinusCircle = dynamic(() => import('../../icons/krefel').then((mod) => mod.MinusCircle));
const Mobile = dynamic(() => import('../../icons/krefel').then((mod) => mod.Mobile));
const Pipe = dynamic(() => import('../../icons/krefel').then((mod) => mod.Pipe));
const Plus = dynamic(() => import('../../icons/krefel').then((mod) => mod.Plus));
const PlusCircle = dynamic(() => import('../../icons/krefel').then((mod) => mod.PlusCircle));
const Qrcode = dynamic(() => import('../../icons/krefel').then((mod) => mod.Qrcode));
const Redo = dynamic(() => import('../../icons/krefel').then((mod) => mod.Redo));
const Search = dynamic(() => import('../../icons/krefel').then((mod) => mod.Search));
const Share = dynamic(() => import('../../icons/krefel').then((mod) => mod.Share));
const Star = dynamic(() => import('../../icons/krefel').then((mod) => mod.Star));
const Store = dynamic(() => import('../../icons/krefel').then((mod) => mod.Store));
const StarFull = dynamic(() => import('../../icons/krefel').then((mod) => mod.StarFull));
const Square = dynamic(() => import('../../icons/krefel').then((mod) => mod.Square));
const SquareChecked = dynamic(() => import('../../icons/krefel').then((mod) => mod.SquareChecked));
const Times = dynamic(() => import('../../icons/krefel').then((mod) => mod.Times));
const TimesCircle = dynamic(() => import('../../icons/krefel').then((mod) => mod.TimesCircle));
const Tools = dynamic(() => import('../../icons/krefel').then((mod) => mod.Tools));
const Trash = dynamic(() => import('../../icons/krefel').then((mod) => mod.Trash));
const Truck = dynamic(() => import('../../icons/krefel').then((mod) => mod.Truck));
const TruckThin = dynamic(() => import('./Truck/Truck'));
const UserCircle = dynamic(() => import('../../icons/krefel').then((mod) => mod.UserCircle));
const User = dynamic(() => import('../../icons/krefel').then((mod) => mod.User));
const YoutubePlay = dynamic(() => import('../../icons/krefel').then((mod) => mod.YoutubePlay));
const XmarkCircleSolid = dynamic(() => import('../../icons/krefel').then((mod) => mod.XmarkCircleSolid));

type BaseProps = StyledIconProps & {
  className?: string;
  iconStyling?: IconStyle;
  onClick?: (event: MouseEvent) => void;
  spin?: boolean;
};

export type IconType = 'custom' | 'fa';
export type IconName = CustomIcon | FaIcon;

type CustomIconProps = BaseProps & {
  name: CustomIcon;
  type?: 'custom';
};

type FaIconProps = BaseProps & {
  name: FaIcon;
  type: 'fa';
};

export type IconProps = CustomIconProps | FaIconProps;

export const isNameOfType = <T extends IconName>(name: IconName): name is T =>
  !!Object.values(ICON_NAMES).find((icon) => icon === name);

type GenericIconProps = Omit<IconProps, 'name' | 'type'> & {
  name: IconName;
  type: IconType;
};

/**
 * Use this icon when the icon type is conditionally set
 **/
export const GenericIcon = (props: GenericIconProps) => {
  if (props.type === 'custom' && isNameOfType<CustomIcon>(props.name)) {
    return <Icon {...props} type="custom" name={props.name as CustomIcon} />;
  } else if (props.type === 'fa') {
    return <Icon {...props} type="fa" name={props.name as FaIcon} />;
  }
  return null;
};

// FIXME: Review the whole icon approach as the Contentful icons allow a lot more icons than are currently supported in the "custom" icons list
// FIXME: Since we use Typescript, we might as wel remove the ICON_NAMES const and replace it with properly types icons. However, this would require a refactor.
// Use icon props as a whole to enable discriminated unions by checking the value of "type"
const Icon = (icon: IconProps) => {
  const {
    className,
    color = '',
    flip,
    iconStyling = ICON_STYLE_DEFAULT,
    rotate,
    size = 150,
    spin = false,
    ...rest
  } = icon;

  const props = { className: 'icon' };

  // If no custom value is give, return the custom icons by default
  if (typeof icon.type === 'undefined' || icon.type === 'custom') {
    const CustomIcon: { [key in CustomIcon]?: JSX.Element } = {
      [ICON_NAMES.BAN]: <Ban {...props} />,
      [ICON_NAMES.BARS]: <Bars {...props} />,
      [ICON_NAMES.CART]: <Cart {...props} />,
      [ICON_NAMES.CART_PLUS]: <CartPlus {...props} />,
      [ICON_NAMES.CHECK]: <Check {...props} />,
      [ICON_NAMES.CHECK_CIRCLE]: <CheckCircle {...props} />,
      [ICON_NAMES.CHECK_CIRCLE_SOLID]: <CheckCircleSolid {...props} />,
      [ICON_NAMES.CHEVRON]: <Chevron {...props} />,
      [ICON_NAMES.CIRCLE]: <Circle {...props} />,
      [ICON_NAMES.CLICK_COLLECT]: <ClickCollect {...props} />,
      [ICON_NAMES.CLOCK]: <Clock {...props} />,
      [ICON_NAMES.DOWNLOAD]: <Download {...props} />,
      [ICON_NAMES.EDIT]: <Edit {...props} />,
      [ICON_NAMES.ENVELOPE]: <Envelope {...props} />,
      [ICON_NAMES.EXCLAMATION_CIRCLE]: <ExclamationCircle {...props} />,
      [ICON_NAMES.EYE]: <Eye {...props} />,
      [ICON_NAMES.EYE_SLASH]: <EyeSlash {...props} />,
      [ICON_NAMES.FILTER]: <Filter {...props} />,
      [ICON_NAMES.HEART]: <Heart {...props} />,
      [ICON_NAMES.HEART_FULL]: <HeartFull {...props} />,
      [ICON_NAMES.INFO_CIRCLE]: <InfoCircle {...props} />,
      [ICON_NAMES.LIST]: <List {...props} />,
      [ICON_NAMES.LOCATION]: <Location {...props} />,
      [ICON_NAMES.LOCATION_PIN_WITH_BOX]: <LocationPinWithBoxIcon {...props} />,
      [ICON_NAMES.LOGO]: <Logo {...props} />,
      [ICON_NAMES.MAP_MARKER_ALT]: <MapMarkerAlt {...props} />,
      [ICON_NAMES.MENU_PRODUCT]: <MenuProduct {...props} />,
      [ICON_NAMES.MINUS_CICLE]: <MinusCircle {...props} />,
      [ICON_NAMES.MOBILE]: <Mobile {...props} />,
      [ICON_NAMES.PLUS]: <Plus {...props} />,
      [ICON_NAMES.PLUS_CIRCLE]: <PlusCircle {...props} />,
      [ICON_NAMES.QRCODE]: <Qrcode {...props} />,
      [ICON_NAMES.REDO]: <Redo {...props} />,
      [ICON_NAMES.SEARCH]: <Search {...props} />,
      [ICON_NAMES.SHARE]: <Share {...props} />,
      [ICON_NAMES.STAR]: <Star {...props} />,
      [ICON_NAMES.SQUARE]: <Square {...props} />,
      [ICON_NAMES.SQUARE_CHECKED]: <SquareChecked {...props} />,
      [ICON_NAMES.STAR_FULL]: <StarFull {...props} />,
      [ICON_NAMES.STORE]: <Store {...props} />,
      [ICON_NAMES.TIMES]: <Times {...props} />,
      [ICON_NAMES.TIMES_CIRCLE]: <TimesCircle {...props} />,
      [ICON_NAMES.TOOLS]: <Tools {...props} />,
      [ICON_NAMES.TRASH]: <Trash {...props} />,
      [ICON_NAMES.TRUCK]: <Truck {...props} />,
      [ICON_NAMES.TRUCK_THIN]: <TruckThin {...props} />,
      [ICON_NAMES.USER_CIRCLE]: <UserCircle {...props} />,
      [ICON_NAMES.USER]: <User {...props} />,
      [ICON_NAMES.YOUTUBE_PLAY]: <YoutubePlay {...props} />,
      [ICON_NAMES.PIPE]: <Pipe {...props} />,
      [ICON_NAMES.XMARK_CIRCLE_SOLID]: <XmarkCircleSolid {...props} />,
    };

    const foundIcon = CustomIcon[icon.name];

    if (foundIcon) {
      return (
        <StyledIcon className={className} color={color} flip={flip} rotate={rotate} size={size} {...rest}>
          {foundIcon}
        </StyledIcon>
      );
    }
  }

  return (
    <StyledIcon className={className} color={color} flip={flip} rotate={rotate} size={size} spin={spin} {...rest}>
      {/* FIXME: Remove the typecast once icons are properly typed. Right now we need to allow this fallback. */}
      <FontAwesomeIcon {...props} icon={[iconStyling, icon.name as FaIcon]} />
    </StyledIcon>
  );
};

export default Icon;
